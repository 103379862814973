/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * A collection of appliance categories used to evaluate brigade coverage statistics.

 */
export type BrigadeCoverageApplianceCategoryGroup =
  (typeof BrigadeCoverageApplianceCategoryGroup)[keyof typeof BrigadeCoverageApplianceCategoryGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrigadeCoverageApplianceCategoryGroup = {
  "cat-1-4": "cat-1-4",
  "cat-6": "cat-6",
  "cat-7-8": "cat-7-8",
  "cat-9": "cat-9",
  "cat-10-11": "cat-10-11",
  "cat-13": "cat-13",
  "cat-15": "cat-15",
} as const;
