/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Fire danger ratings give you an indication of the consequences of a fire, if one was to start. The higher the fire danger, the more dangerous the conditions. Fire danger ratings are determined by bracketing the fire behaviour index.
* `not-available` -  No rating is available either because ratings are
  not currently being published, possibly due to the absence of an
  active fire season, or because an up-to-date value cannot be
  retrieved.

* `no-rating` - 0-11
* `moderate` - 12-23
* `high-a` - 24-34
* `high-b` - 35-50
* `extreme` - 50-100
* `catastrophic` - 100+

 */
export type FireDangerRating =
  (typeof FireDangerRating)[keyof typeof FireDangerRating];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FireDangerRating = {
  "not-available": "not-available",
  "no-rating": "no-rating",
  moderate: "moderate",
  "high-a": "high-a",
  "high-b": "high-b",
  extreme: "extreme",
  catastrophic: "catastrophic",
} as const;
