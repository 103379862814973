/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Geometries can come in three different variants.

Allowed values are:
  * `original` - the "true curves" from the source data.
  * `simplified` - same as original with reduced complexity while still preserving topology.
  * `very-simplified` - low quality geometry for low zoom levels (e.g. state-level view).

 */
export type GeometryVariant =
  (typeof GeometryVariant)[keyof typeof GeometryVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeometryVariant = {
  original: "original",
  simplified: "simplified",
  "very-simplified": "very-simplified",
} as const;
