/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Enum specifying reasons for an "acknowledged" brigade coverage deficit assessment.

 */
export type AcknowledgedBrigadeCoverageDeficitAssessmentReason =
  (typeof AcknowledgedBrigadeCoverageDeficitAssessmentReason)[keyof typeof AcknowledgedBrigadeCoverageDeficitAssessmentReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcknowledgedBrigadeCoverageDeficitAssessmentReason = {
  "sent-out-of-area-strike-team": "sent-out-of-area-strike-team",
  "appliances-being-serviced": "appliances-being-serviced",
  "unable-to-cover": "unable-to-cover",
} as const;
