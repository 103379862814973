/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type AcknowledgedBrigadeCoverageDeficitAssessmentAssessment =
  (typeof AcknowledgedBrigadeCoverageDeficitAssessmentAssessment)[keyof typeof AcknowledgedBrigadeCoverageDeficitAssessmentAssessment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcknowledgedBrigadeCoverageDeficitAssessmentAssessment = {
  acknowledged: "acknowledged",
} as const;
