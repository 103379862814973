/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Alert levels are a set of levels used across the system to express the relative importance or seriousness of a given alert.

 */
export type AlertLevel = (typeof AlertLevel)[keyof typeof AlertLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertLevel = {
  danger: "danger",
  warning: "warning",
  info: "info",
} as const;
